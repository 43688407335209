import * as React from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function Review({ name, cardName, total, title, description, cardNumber, expirationDate, email, paymentType, pixQrCode, pixCode }) {
  
  const minutes = 5

  const [seconds, setSeconds] = React.useState(minutes * 60)
  
  function copyPixCode(buttonElement) {
    buttonElement.innerText = 'Código copiado ✅'
    navigator.clipboard.writeText(pixCode);
  }

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
  }

  React.useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds > 0) {
          return prevSeconds - 1
        } else {
          clearInterval(timer)
          return 0
        }
      })
    }, 1000)

    return () => clearInterval(timer)

  }, [minutes])

  return (
    <Stack spacing={2}>
      <List disablePadding>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary={title} secondary={description} />
        </ListItem>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            {total}
          </Typography>
        </ListItem>
      </List>
      <Divider />
      <Stack
        direction="column"
        divider={<Divider flexItem />}
        spacing={2}
        sx={{ my: 2 }}
      >
        <div>
          <Typography variant="subtitle2" gutterBottom>
            Dados do aluno
          </Typography>
          <Typography gutterBottom>{name}</Typography>
          <Typography gutterBottom>{email}</Typography>
        </div>

        {
          paymentType === 'creditCard' ? (
            <div>
              <Typography variant="subtitle2" gutterBottom>
                Detalhes do pagamento
              </Typography>
    
              <Grid container>
                <Stack
                  direction="row"
                  spacing={1}
                  useFlexGap
                  sx={{ width: '100%', mb: 1 }}
                >
                  <Typography variant="body1" color="text.secondary">
                    Nome no cartão
                  </Typography>
                  <Typography variant="body2">{cardName}</Typography>
                </Stack>
    
                <Stack
                  direction="row"
                  spacing={1}
                  useFlexGap
                  sx={{ width: '100%', mb: 1 }}
                >
                  <Typography variant="body1" color="text.secondary">
                    Número do cartão
                  </Typography>
                  <Typography variant="body2">{cardNumber}</Typography>
                </Stack>
    
                <Stack
                  direction="row"
                  spacing={1}
                  useFlexGap
                  sx={{ width: '100%', mb: 1 }}
                >
                  <Typography variant="body1" color="text.secondary">
                    Data de vencimento
                  </Typography>
                  <Typography variant="body2">{expirationDate}</Typography>
                </Stack>
              </Grid>
            </div>
          ) : ''
        }

        {
          paymentType === 'PIX' ? (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '36px', alignItems: 'center' }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                Pague o seguinte QR Code para finalizar seu pagamento:
              </Typography>
              <img src={pixQrCode} alt="Oops, ocorreu um erro"/>
              <button onClick={(e) => copyPixCode(e.currentTarget)} style={{ padding: '20px', borderRadius: '8px', textAlign: 'center', backgroundColor: 'rgb(152, 101, 224)', color: 'white' }}>
                Copiar código do QR Code                
              </button>
              <div className='text-sm'>Pague o PIX em até: {formatTime(seconds)}</div>
            </div>
          ) : ''
        }
       

      </Stack>
    </Stack>
  );
}