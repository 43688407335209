import * as React from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded';
import SimCardRoundedIcon from '@mui/icons-material/SimCardRounded';

import { styled } from '@mui/system';
import { InputLabel, MenuItem, Select } from '@mui/material';

const FormGrid = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export default function PaymentForm({ paymentType, setPaymentType, cardNumber, setCardNumber, cvv, setCvv, expirationDate, setExpirationDate, cardName, setCardName, setCardInstallments, maxInstallments, showPix, showCreditCard, showSubscription }) {

  const [labelVisible, setLabelVisible] = React.useState(true);

  const handleSelectChange = (e) => {
    setCardInstallments(e.target.value);
    setLabelVisible(false);
  };

  const handlePaymentTypeChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setPaymentType(event.target.value);
  };

  const handleCardNumberChange = (event: { target: { value: string } }) => {
    const value = event.target.value.replace(/\D/g, '');
    const formattedValue = value.replace(/(\d{4})(?=\d)/g, '$1 ');
    if (value.length <= 16) {
      setCardNumber(formattedValue);
    }
  };

  const handleCvvChange = (event: { target: { value: string } }) => {
    const value = event.target.value.replace(/\D/g, '');
    if (value.length <= 3) {
      setCvv(value);
    }
  };

  const handleExpirationDateChange = (event: { target: { value: string } }) => {
    const value = event.target.value.replace(/\D/g, '');
    const formattedValue = value.replace(/(\d{2})(?=\d{2})/, '$1/');
    if (value.length <= 4) {
      setExpirationDate(formattedValue);
    }
  };

  function renderInstallments() {
    const installments: Array<number> = [];
    for (let currentInstallment = 2; currentInstallment <= maxInstallments; currentInstallment++) {
      installments.push(currentInstallment)
    }
    return installments
  }

  return (
    <Stack spacing={{ xs: 3, sm: 6 }} useFlexGap>
      <FormControl component="fieldset" fullWidth> 
        <RadioGroup
          aria-label="Payment options"
          name="paymentType"
          value={paymentType}
          onChange={handlePaymentTypeChange}
          sx={{
            flexDirection: { sm: 'column', md: 'row' },
            gap: 2,
          }}
        >
          {
            showCreditCard &&
            <Card
              raised={paymentType === 'creditCard'}
              sx={{
                maxWidth: { sm: '100%', md: '50%' },
                flexGrow: 1,
                outline: '1px solid',
                outlineColor: paymentType === 'creditCard' ? 'primary.dark' : 'divider',
                backgroundColor: paymentType === 'creditCard' ? 'primary.dark' : '',
                color: paymentType === 'creditCard' ? 'white' : ''
              }}
            >
              <CardActionArea onClick={() => setPaymentType('creditCard')}>
                <CardContent sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <CreditCardRoundedIcon color={paymentType === 'creditCard' ? 'secondary' : 'primary'} fontSize="small" />
                  <Typography fontWeight="medium">Cartão de crédito</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          }
          {
            showPix &&
            <Card
              raised={paymentType === 'PIX'}
              sx={{
                maxWidth: { sm: '100%', md: '50%' },
                flexGrow: 1,
                outline: '1px solid',
                outlineColor: paymentType === 'PIX' ? 'primary.dark' : 'divider',
                backgroundColor: paymentType === 'PIX' ? 'primary.dark' : '',
                color: paymentType === 'PIX' ? 'white' : ''
              }}
            >
              <CardActionArea onClick={() => setPaymentType('PIX')}>
                <CardContent sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <img width={20} height={20} src='/assets/pixLogo.png' alt=""/>
                  <Typography fontWeight="medium">PIX  <span style={{ color: '#36067A', fontWeight: 'bold' }}> 5% OFF</span></Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          }
          {
            showSubscription &&
            <Card
              raised={paymentType === 'subscription'}
              sx={{
                maxWidth: { sm: '100%', md: '100%' },
                flexGrow: 1,
                outline: '1px solid',
                outlineColor: paymentType === 'subscription' ? 'primary.dark' : 'divider',
                backgroundColor: paymentType === 'subscription' ? 'primary.dark' : '',
                color: paymentType === 'subscription' ? 'white' : ''
              }}
            >
              <CardActionArea onClick={() => setPaymentType('subscription')}>
                <CardContent sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <CreditCardRoundedIcon color={paymentType === 'pix' ? 'secondary' : 'primary'} fontSize="small" />
                  <Typography fontWeight="medium">Assinatura (Pague mensalmente, sem comprometer o limite)</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          }
       
        </RadioGroup>

        {paymentType === 'creditCard' && maxInstallments !== 1 && (
          <FormControl sx={{ marginTop: 2 }}>
            {labelVisible && (
              <InputLabel id="installments-select-label" sx={{ top: '-5px' }}>
                Parcelar Compra
              </InputLabel>
            )}
            <Select
              labelId="installments-select-label"
              label="Parcelar Pagamento"
              id="installments-select"
              onChange={handleSelectChange}
              defaultValue=""
              sx={{
                maxWidth: { sm: '100%', md: '100%' },
                flexGrow: 1,
                outline: '1px solid',
                outlineColor: paymentType === 'creditCard' ? 'primary.main' : 'divider',
                backgroundColor: paymentType === 'creditCard' ? 'background.default' : '',
              }}
            >
              <MenuItem value="" disabled>
                Parcelar Compra
              </MenuItem>
              
              <MenuItem value={1}>À Vista</MenuItem>
              {
                renderInstallments().map((installment) => {
                  return (
                    <MenuItem value={installment}>{installment}x sem juros</MenuItem>
                  ) 
                })
              }
            </Select>
          </FormControl>
        )}

        {paymentType === 'subscription' && maxInstallments !== 1 && (
          <FormControl sx={{ marginTop: 2 }}>
            {labelVisible && (
              <InputLabel id="installments-select-label" sx={{ top: '-5px' }}>
                Quantidade de ciclos (cobranças)
              </InputLabel>
            )}
            <Select
              labelId="installments-select-label"
              label="Parcelar Pagamento"
              id="installments-select"
              onChange={handleSelectChange}
              defaultValue=""
              sx={{
                maxWidth: { sm: '100%', md: '100%' },
                flexGrow: 1,
                outline: '1px solid',
                outlineColor: paymentType === 'creditCard' ? 'primary.main' : 'divider',
                backgroundColor: paymentType === 'creditCard' ? 'background.default' : '',
              }}
            >
              <MenuItem value="" disabled>
                Dividir em
              </MenuItem>
              
              <MenuItem value={1}>À Vista</MenuItem>
              {
                renderInstallments().map((installment) => {
                  return (
                    <MenuItem value={installment}>{installment} meses</MenuItem>
                  ) 
                })
              }
            </Select>
          </FormControl>
        )}

      </FormControl>
      {paymentType === 'creditCard' && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              p: 3,
              height: { xs: 300, sm: 350, md: 375 },
              width: '100%',
              borderRadius: '20px',
              border: '1px solid ',
              borderColor: 'divider',
              backgroundColor: 'background.paper',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.05)',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="subtitle2">Dados do cartão:</Typography>
              <CreditCardRoundedIcon sx={{ color: 'text.secondary' }} />
            </Box>
            <SimCardRoundedIcon
              sx={{
                fontSize: { xs: 48, sm: 56 },
                transform: 'rotate(90deg)',
                color: 'text.secondary',
              }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                gap: 2,
              }}
            >
              <FormGrid sx={{ flexGrow: 1 }}>
                <FormLabel htmlFor="card-number" required>
                  Número do cartão
                </FormLabel>
                <OutlinedInput
                  id="card-number"
                  autoComplete="card-number"
                  placeholder="0000 0000 0000 0000"
                  required
                  value={cardNumber}
                  onChange={handleCardNumberChange}
                />
              </FormGrid>
              <FormGrid sx={{ maxWidth: '20%' }}>
                <FormLabel htmlFor="cvv" required>
                  CVV
                </FormLabel>
                <OutlinedInput
                  id="cvv"
                  autoComplete="CVV"
                  placeholder="123"
                  required
                  value={cvv}
                  onChange={handleCvvChange}
                />
              </FormGrid>
            </Box>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <FormGrid sx={{ flexGrow: 1 }}>
                <FormLabel htmlFor="card-name" required>
                  Nome no cartão
                </FormLabel>
                <OutlinedInput
                  id="card-name"
                  autoComplete="card-name"
                  placeholder="Nome no cartão"
                  value={cardName}
                  onChange={(e) => setCardName(e.target.value)}
                  required
                />
              </FormGrid>
              <FormGrid sx={{ flexGrow: 1 }}>
                <FormLabel htmlFor="card-expiration" required>
                  Data de vencimento
                </FormLabel>
                <OutlinedInput
                  id="card-expiration"
                  autoComplete="card-expiration"
                  placeholder="MM/AA"
                  required
                  value={expirationDate}
                  onChange={handleExpirationDateChange}
                />
              </FormGrid>
            </Box>
          </Box>
        </Box>
      )}

      {paymentType === 'PIX' && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              p: 3,
              height: { xs: 300, sm: 350, md: 375 },
              width: '100%',
              borderRadius: '20px',
              border: '1px solid ',
              borderColor: 'divider',
              backgroundColor: 'background.paper',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.05)',
            }}
          >
            <Typography style={{ color: '#36067A' }} variant="subtitle2" gutterBottom>
              Clique em próximo para gerar seu QR code e concluir o pagamento.
            </Typography>
          </Box>
        </Box>
      )}
      {paymentType === 'subscription' && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              p: 3,
              height: { xs: 300, sm: 350, md: 375 },
              width: '100%',
              borderRadius: '20px',
              border: '1px solid ',
              borderColor: 'divider',
              backgroundColor: 'background.paper',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.05)',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="subtitle2">Dados do cartão:</Typography>
              <CreditCardRoundedIcon sx={{ color: 'text.secondary' }} />
            </Box>
            <SimCardRoundedIcon
              sx={{
                fontSize: { xs: 48, sm: 56 },
                transform: 'rotate(90deg)',
                color: 'text.secondary',
              }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                gap: 2,
              }}
            >
              <FormGrid sx={{ flexGrow: 1 }}>
                <FormLabel htmlFor="card-number" required>
                  Número do cartão
                </FormLabel>
                <OutlinedInput
                  id="card-number"
                  autoComplete="card-number"
                  placeholder="0000 0000 0000 0000"
                  required
                  value={cardNumber}
                  onChange={handleCardNumberChange}
                />
              </FormGrid>
              <FormGrid sx={{ maxWidth: '20%' }}>
                <FormLabel htmlFor="cvv" required>
                  CVV
                </FormLabel>
                <OutlinedInput
                  id="cvv"
                  autoComplete="CVV"
                  placeholder="123"
                  required
                  value={cvv}
                  onChange={handleCvvChange}
                />
              </FormGrid>
            </Box>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <FormGrid sx={{ flexGrow: 1 }}>
                <FormLabel htmlFor="card-name" required>
                  Nome no cartão
                </FormLabel>
                <OutlinedInput
                  id="card-name"
                  autoComplete="card-name"
                  placeholder="Nome no cartão"
                  value={cardName}
                  onChange={(e) => setCardName(e.target.value)}
                  required
                />
              </FormGrid>
              <FormGrid sx={{ flexGrow: 1 }}>
                <FormLabel htmlFor="card-expiration" required>
                  Data de vencimento
                </FormLabel>
                <OutlinedInput
                  id="card-expiration"
                  autoComplete="card-expiration"
                  placeholder="MM/AA"
                  required
                  value={expirationDate}
                  onChange={handleExpirationDateChange}
                />
              </FormGrid>
            </Box>
          </Box>
        </Box>
      )}
    </Stack>
  );
}