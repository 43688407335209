function formatMoney(number) {

  const parseToReal = number / 100
  
  let formatedAmount = parseToReal.toFixed(2)
  formatedAmount = formatedAmount.replace('.', ',')
  formatedAmount = formatedAmount.replace(/(\d)(?=(\d{3})+,)/g, '$1.')

  return 'R$ ' + formatedAmount;
}

export default formatMoney