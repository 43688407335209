import axios from "axios";
import { checkoutUrl } from "./lib";

const axiosReq = axios.create({
  baseURL: checkoutUrl,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
axiosReq.interceptors.request.use((config: any) => {
  const headers = { 
    'Content-Type': 'application/json'
  }

  return { ...config, headers }
})

export default axiosReq;