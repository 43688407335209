import * as React from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function Hero({ headingTitle, headingPurpleTitle, headingDescription }) {

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #E8DAFE, #FFF)'
            : `linear-gradient(#CEBCE1, ${alpha('#090E10', 0.0)})`,
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignSelf: 'center',
              textAlign: 'center'
            }}
          >
            <Typography
              component="h1"
              sx={{ 
                fontSize: { xs: 20, md: 36 },
                color: 'hsla(0,0,0,0.87)'
              }}
            >
              {headingTitle}
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: 30, md: 60 },
                fontWeight: 700,
                width: { xs: 309, md: 980 },
                lineHeight: '150%',
                color: (theme) => theme.palette.secondary.light
              }}
            >
              {headingPurpleTitle}
            </Typography>
          </Box>
          <Typography
            textAlign="center"
            color="text.secondary"
            sx={{ fontSize: { xs: 13, md: 24 }, alignSelf: 'center', width: '100%', maxWidth: { xs: 337, md: 865 }}}
          >
            {headingDescription}
          </Typography>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignSelf="center"
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}
          >
            <button className='px-4 w-[381px] max-w-[100%] desktop:max-w-none py-1 text-[14px] font-bold rounded-[10px] text-[20px] text-[#37067A] border-2 border-[#37067A]'>
              <a href='#formComponent'>
                Receber Raio-X
              </a>
            </button>
          </Stack>
          <Typography variant="caption" textAlign="center">
            Ao clicar em &quot;RECEBER Raio-X&quot; você aceita nossos&nbsp;
            <Link href="#" sx={{ color: (theme) => theme.palette.secondary.main }}>
              Termos & Condições
            </Link>
            .
          </Typography>
        </Stack>

      </Container>
      <div className='flex flex-col items-center justify-center'>
        <a href='#pricing' className='w-[381px] max-w-[90%] text-white bg-[#37067A] py-[6px] text-center font-bold text-[20px] rounded-md mt-5'>
          Matricule-se Agora!
        </a>
      </div>
    </Box>
  );
}