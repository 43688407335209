import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Avatar, alpha } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

import { Autoplay, Navigation, Pagination } from 'swiper/modules';

import VideoPlayer from '../../../Organisms/VideoPlayer';

export default function Testimonials({ userTestimonials, approvalsImage, aprrovalsImageMobile }) {

  return (
    <div>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: { xs: 'start', md: 'center' },
          gap: 8 ,
          mt: { xs: 10, md: 15 }
        }}
      >
        <Box
            sx={{
              width: '100%',
              textAlign: { xs: 'left', md: 'center' },
              maxWidth: { xs: 305, md: 860 }
            }}
          >
          <Typography 
            component="h2"
            variant="h4"
            color="secondary"
          >
            Nossa Comunidade de Aprovação
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Quem passa pelo MedAcademy, aprova.
          </Typography>
        </Box>
        <Box
            sx={{
              width: '100%',
              alignSelf: 'center',
              maxWidth: 1166,
            }}
            style={{ minHeight: '234px', maxHeight: '462px', height: 'auto' }}
          >
          <Swiper className='hidden desktop:flex ' style={{ width: '100%', height: '100%', minHeight: '234px' }} loop={true} autoplay={{ delay: 3000 }} pagination={{ clickable: true }} navigation={true} modules={[Navigation, Pagination, Autoplay]}>
            {
              approvalsImage !== null &&
              approvalsImage.map((image, index) => {
                return (
                  <SwiperSlide key={index}>
                    <img style={{ height: '100%', maxHeight: '462px', objectPosition: 'center', objectFit: 'cover', width: 'auto', marginLeft: 'auto', marginRight: 'auto' }} src={image.url} alt=""/>
                  </SwiperSlide>
                )
              })
            }
          </Swiper>
          <Swiper className='flex desktop:hidden ' style={{ width: '100%', height: '100%', minHeight: '234px' }} loop={true} autoplay={{ delay: 3000 }} pagination={{ clickable: true }} navigation={true} modules={[Navigation, Pagination, Autoplay]}>
            {
              aprrovalsImageMobile !== null &&
              aprrovalsImageMobile.map((image, index) => {
                return (
                  <SwiperSlide key={index}>
                    <img style={{ height: '100%', maxHeight: '462px', objectPosition: 'center', objectFit: 'cover', width: 'auto', marginLeft: 'auto', marginRight: 'auto' }} src={image.url} alt=""/>
                  </SwiperSlide>
                )
              })
            }
          </Swiper>
        </Box>
        
        <section id="testimonials">
          <Container
            id="testimonials"
            sx={{
              pt: { xs: 4, sm: 12 },
              pb: { xs: 8, sm: 16 },
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: { xs: 'left', md: 'center' },
              gap: { xs: 3, sm: 6 },
            }}
          >
            <Grid container spacing={2}>
              {userTestimonials.map((testimonial, index) => (
                <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex' }}>
                  <Card
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      flexGrow: 1,
                      p: 1,
                    }}
                  >
                    <CardContent>
                      <Typography variant="body2" color="text.secondary">
                        {testimonial.testmonial}
                      </Typography>
                    </CardContent>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        pr: 2,
                      }}
                    >
                      <CardHeader
                        avatar={<Avatar alt={testimonial.name} src={testimonial.avatar.url} />}
                        title={testimonial.name}
                        subheader={testimonial.occupation}
                      />
                    </Box>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </section>
      </Container>
      <div className='flex flex-col items-center gap-4 text-start desktop:text-center desktop:pt-[60px] mb-10 desktop:mb-20 px-5'>
        <h1 className='text-[#37067A] !leading-[150%] text-[30px] desktop:text-[42px] font-bold'>Confira uma prévia das nossas aulas</h1>
        <p className='text-black !leading-[150%] text-[16px] desktop:text-[24px] font-segoe font-normal'>Para ter acesso a mais conteúdos como esse, é só se matricular com a gente!</p>
      </div>
      <Box
          id="image"
          sx={(theme) => ({
            mt: { xs: 8, sm: 10 },
            alignSelf: 'center',
            height: { xs: 200, sm: 700 },
            width: '100%',
            backgroundImage:
              theme.palette.mode === 'light'
                ? 'url("/static/images/templates/templates-images/hero-light.png")'
                : 'url("/static/images/templates/templates-images/hero-dark.png")',
            backgroundSize: 'cover',
            overflow: 'hidden',
            borderRadius: '10px',
            outline: '1px solid',
            outlineColor:
              theme.palette.mode === 'light'
                ? alpha('#E8DAFE', 0.5)
                : alpha('#CEBCE1', 0.1),
            boxShadow:
              theme.palette.mode === 'light'
                ? `0 0 12px 8px ${alpha('#E8DAFE', 0.2)}`
                : `0 0 24px 12px ${alpha('#CEBCE1', 0.2)}`,
          })}
        >
          <VideoPlayer />
        </Box>
    </div>
  );
}