import * as React from 'react';
// import { PaletteMode } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppAppBar from './components/AppAppBar.tsx';
import Hero from './components/Hero.tsx';
// import LogoCollection from './components/LogoCollection.tsx';
import Highlights from './components/Highlights.tsx';
import Pricing from './components/Pricing.tsx';
import Features from './components/Features.tsx';
import Testimonials from './components/Testmonials.tsx';
import FAQ from './components/FAQ.tsx';
import Footer from './components/Footer.tsx';
import getLPTheme from './getLPTheme.tsx';
import FormComponent from './components/FormComponent.tsx';
import useSwr from 'swr';
import strapiURL, { fetcher } from '../../../lib/lib.js';
import { Avatar } from '@mui/material';

export default function LandingPage() {
  const [email, setEmail] = React.useState<string>('')

  const showCustomTheme = true
  const mode = 'light'

  const LPtheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });
  
  const { data, isLoading, error } = useSwr(`${strapiURL}api/landing-page?populate[0]=approvalsImages&populate[1]=userTestmonials&populate[2]=userTestmonials.avatar&populate[3]=cardAboutUs&populate[4]=cardAboutUs.icon&populate[5]=cardAboutUs.image&populate[6]=faq&populate[7]=approvalsImagesMobile`, fetcher);

  if (isLoading || error) {
    return (
      <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
        <CssBaseline />
        <AppAppBar />
        <Hero headingTitle="Faça parte da comunidade de" headingPurpleTitle="Médicos aprovados no CERMAM" headingDescription="Cadastre-se para receber o Raio-X CERMAM e estar pronto para a próxima etapa da sua jornada de sucesso na Medicina." />
        <Box sx={{ bgcolor: 'background.default' }}>
  
          {/* userTestimonials & approvalsImages strapi */}
          <Testimonials userTestimonials={[
              {
                avatar: <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />,
                name: 'Remy Sharp',
                occupation: 'Senior Engineer',
                testimonial:
                  "I absolutely love how versatile this product is! Whether I'm tackling work projects or indulging in my favorite hobbies, it seamlessly adapts to my changing needs. Its intuitive design has truly enhanced my daily routine, making tasks more efficient and enjoyable.",
              },
              {
                avatar: <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />,
                name: 'Travis Howard',
                occupation: 'Lead Product Designer',
                testimonial:
                  "One of the standout features of this product is the exceptional customer support. In my experience, the team behind this product has been quick to respond and incredibly helpful. It's reassuring to know that they stand firmly behind their product.",
              },
              {
                avatar: <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />,
                name: 'Cindy Baker',
                occupation: 'CTO',
                testimonial:
                  'The level of simplicity and user-friendliness in this product has significantly simplified my life. I appreciate the creators for delivering a solution that not only meets but exceeds user expectations.',
              }
            ]} 
            approvalsImage={[ '1', '2', '3' ]}
            aprrovalsImageMobile={[ '1', '2', '3' ]}
          />
          <FormComponent emailValue={email} setEmail={setEmail} raioXForm />
  
          {/* cardAboutUs strapi */}
          <Features items={[
              {
                icon: '',
                title: 'Ensino presencial de qualidade',
                description: 'Nossas aulas presenciais oferecem uma altíssima qualidade de ensino para que os médicos não percam o foco no estudo.',
                image: { url: '' }
              },
              {
                icon: '',
                title: 'Apostilas exclusivas',
                description: 'Nosso conteúdo das apostilas é exclusivo e de alta qualidade para quem deseja ter alto desempenho.',
                image: { url: '' }
              },
              {
                icon: '',
                title: 'O melhor curso para o CERMAM',
                description: 'Toda a nossa metodologia é focada na aprovação para a prova do CERMAM',
                image: { url: '' }
              },
            ]
          } 
          />
          <FormComponent emailValue={email} setEmail={setEmail} />
          <Divider />
          <Divider />
          <Highlights />
          <Divider />
          <Pricing />
          <Divider />
          {/* FAQ strapi */}
          <FAQ faq={[{ question: 'Pergunta Placeholder?', response: 'Resposta Placeholder.' }, { question: 'Pergunta Placeholder?', response: 'Resposta Placeholder.' }, { question: 'Pergunta Placeholder?', response: 'Resposta Placeholder.' }]} />
          <Divider />
          <Footer />
        </Box>
      </ThemeProvider>
    );
  }

  console.log(data);

  return (
    <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
      <CssBaseline />
      <AppAppBar />
      <Hero headingTitle={data.headingTitle} headingPurpleTitle={data.headingPurpleTitle} headingDescription={data.headingDescription} />
      <Box sx={{ bgcolor: 'background.default' }}>

        {/* userTestimonials & approvalsImages strapi */}
        <Testimonials userTestimonials={data.userTestmonials} 
          approvalsImage={data.approvalsImages}
          aprrovalsImageMobile={data.approvalsImagesMobile}
        />
        <FormComponent emailValue={email} setEmail={setEmail} raioXForm />

        {/* cardAboutUs strapi */}
        <Features items={data.cardAboutUs} 
        />
        <FormComponent id='formProvas' emailValue={email} setEmail={setEmail} />
        <Divider />
        <Divider />
        <Highlights />
        <Divider />
        <Pricing />
        <Divider />
        {/* FAQ strapi */}
        <FAQ faq={data.faq} />
        <Divider />
        <Footer />
      </Box>
    </ThemeProvider>
  );
  
}