import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FAQ({ faq }) {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <section id="faq">
      <Container
        id="faq"
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          color="text.primary"
          sx={{
            width: '100%',
            maxWidth: { xs: 338, md: 720 },
            textAlign: { xs: 'left', md: 'center' },
          }}
        >
          Sua <Typography display="inline" component="h2" variant="h4" color="secondary">dúvida</Typography> pode estar aqui.
        </Typography>
        <Box sx={{ width: '100%' }}>
          {
            faq.map((question, index) => {
              return (
                <Accordion
                  key={index}
                  expanded={expanded === index}
                  onChange={handleChange(index)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <Typography component="h3" variant="subtitle2">
                      {question.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="body2"
                      gutterBottom
                      sx={{ maxWidth: { sm: '100%', md: '70%' } }}
                    >
                      {question.response}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              )
            })
          }
        </Box>
      </Container>
    </section>
  );
}