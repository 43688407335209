import * as React from "react";

import { getPostBySlug } from "../lib/api.ts";
import Container from "../_components/container.tsx";
import { PostBody } from "../_components/post-body.tsx";
import { PostHeader } from "../_components/post-header.tsx";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import getLPTheme from '../../Templates/LandingPage/getLPTheme.tsx';

import type { Post } from "../interfaces/post.ts";
import { useParams } from "react-router-dom";
import AppAppBar from "../../Templates/LandingPage/components/AppAppBar.tsx";

export default function Post() {
  
  const { slug } = useParams();
  const [fetchDone, setFetchDone] = React.useState(false);
  const [post, setPost] = React.useState<Post>({ coverImage: { url: '' }, slug: '', createdAt: '', title: '', excerpt: '', content: '' })

  const showCustomTheme = true
  const mode = 'light'

  const LPtheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  React.useEffect(() => {
    async function fetchPost() {
      if (slug !== undefined) {
        const post = await getPostBySlug(slug);
      
        setPost(post);
        setFetchDone(true)
      }
    }

    fetchPost();
  }, [])
  
  
  if (fetchDone === false) {
    return <div />;
  }

  return (
    <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
      <Container>
        <AppAppBar />
        <article className="mb-32 mt-32">
          <PostHeader
            title={post.title}
            coverImage={post.coverImage.url}
            date={post.createdAt}
          />
          <PostBody content={post.content} />
        </article>
      </Container>
    </ThemeProvider>
  );
}