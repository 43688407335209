import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { fetchCourses } from '../../../../lib/apiCheckout.ts';
import formatMoney from '../../../../utils/formatMoney.js'

interface CourseInterface {
  title: string,
  amount: number,
  courseHighlights: Array<{ texto: string }>,
  slug: string
  max_installments: number
  pagarme_plan_id: string
}

export default function Pricing() {
  const [ courses, setCourses ] = React.useState<Array<CourseInterface>>([]);

  React.useEffect(() => {
    async function fetchStuff() {
      const data = await fetchCourses();
      setCourses(data);
    }

    fetchStuff();
  }, []);

  if (courses.length === 0) {
    return <div />
  }

  console.log(courses);

  return (
    <section id="pricing">
      <Container
        id="pricing"
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4" color="secondary">
            Acesso ao MedAcademy
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Entre em contato para saber mais informações sobre o nosso curso 
            <br />
            preparatório do MedAcademy com foco em aprovação no CERMAM
          </Typography>
        </Box>
        <Grid container spacing={3} alignItems="start" justifyContent="center">
          {courses.map((tier) => (
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={tier.title === 'Enterprise' ? 12 : 6}
              md={4}
            >
              <Card
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 4,
                  border: tier.title === 'Assinatura' ? '1px solid' : undefined,
                  borderColor:
                    tier.title === 'Assinatura' ? 'primary.main' : undefined,
                  background:
                    tier.title === 'Assinatura'
                      ? 'linear-gradient(#4C2089, #36067A)'
                      : undefined,
                }}
              >
                <CardContent>
                  <Typography style={{ fontSize: '24px' }} sx={{ fontSize: '48px', color: '#37067A', fontWeight: 700, marginBottom: '20px' }}>
                    {tier.title}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'baseline',
                      gap: 3,
                      color: tier.title === 'Assinatura' ? 'grey.50' : undefined,
                    }}
                  >
                    {
                      tier.max_installments !== 1 ? (
                        <div className='flex flex-col'>
                          <Typography style={{ fontSize: '12px' }} sx={{ fontSize: '48px', color:'black', fontWeight: 700 }}>
                            em até {tier.max_installments} vezes de:
                          </Typography>
                          <Typography style={{ fontSize: '48px', lineHeight: '110%' }} sx={{ fontSize: '48px', color:'black', fontWeight: 700 }}>
                            {formatMoney(tier.amount / tier.max_installments)}
                          </Typography>
                          <Typography style={{ fontSize: '12px', marginTop: '4px' }} sx={{ fontSize: '48px', color:'black', fontWeight: 700 }}>
                            sem juros, ou {formatMoney(tier.amount)} a vista.
                          </Typography>
                        </div>
                      ) : (
                        <Typography style={{ fontSize: '48px', lineHeight: '110%' }} sx={{ fontSize: '48px', color:'black', fontWeight: 700 }}>
                          {formatMoney(tier.amount)}
                        </Typography>
                      )
                    }
                    {/* <Typography component="h3" variant="h6">
                      
                    </Typography> */}
                  </Box>
                  <Divider
                    sx={{
                      my: 2,
                      opacity: 0.2,
                      borderColor: 'grey.500',
                    }}
                  />
                  {tier.courseHighlights.map((line) => (
                    <Box
                      key={line.texto}
                      sx={{
                        py: 1,
                        display: 'flex',
                        gap: 1.5,
                        alignItems: 'center',
                      }}
                    >
                      <CheckCircleRoundedIcon
                        sx={{
                          width: 20,
                          color:
                            tier.title === 'Assinatura'
                              ? 'primary.light'
                              : 'primary.main',
                        }}
                      />
                      <Typography
                        component="text"
                        variant="subtitle2"
                        sx={{
                          color: 'black'
                        }}
                      >
                        {line.texto}
                      </Typography>
                    </Box>
                  ))}
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    sx={{
                      backgroundColor: "#9865E0",
                      '&:hover': {
                        backgroundColor: '#5f469c'
                      },
                      color: 'white',
                      fontSize: { sm: '14px', md: '20px' }
                    }}

                    component="a"
                    href={`/checkout/${tier.slug}`}
                    target="_blank"
                  >
                    Matricule-se agora
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </section>
  );
}