import * as React from 'react';

import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styled } from '@mui/system';
import { Box } from '@mui/material';

const FormGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export default function AddressForm({ name, setName, cpf, setCpf, email, setEmail, address, setAddress, state, setState, cep, setCep, city, setCity, phone, setPhone }) {
  function handleCpfChange(value: string) {
    if (value.length > 14) {
      value = value.substring(0, 14);
    }
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{3})(\d)/, '$1.$2');
    value = value.replace(/(\d{3})(\d)/, '$1.$2');
    value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    setCpf(value);
  }

  function handleStateChange(value: string) {
    if (value.length > 2) {
      value = value.substring(0, 2);
    }

    setState(value);
  }

  function handlePhone(value: string) {
    value = value.substring(0, 12)
    setPhone(value);
  }

  return (
    <Grid container spacing={3}>
      <FormGrid item xs={12} >
        <FormLabel htmlFor="nome" required>
          Nome completo
        </FormLabel>
        <OutlinedInput
          id="name"
          name="nome"
          type="name"
          autoComplete='Nome Name nome name'
          placeholder="Nome completo"
          required
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
      </FormGrid>

      <FormGrid item xs={12}>
        <FormLabel htmlFor="email" required>
          Email
        </FormLabel>
        <OutlinedInput
          id="email"
          name="email"
          type="email"
          placeholder="seuemail@seuprovedor.com"
          autoComplete="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          required
        />
      </FormGrid>

      <FormGrid item xs={12}>
        <Box sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: { xs: '24px', md: '16px' }
        }}>
          <FormGrid item xs={12}>
            <FormLabel htmlFor="phone" required>
              Telefone
            </FormLabel>
            <OutlinedInput
              id="phone"
              name="phone"
              type="number"
              placeholder="Telefone (apenas números)"
              autoComplete="phone telefone"
              onChange={(e) => handlePhone(e.target.value)}
              value={phone}
              required
            />
          </FormGrid>

          <FormGrid item xs={12}>
            <FormLabel htmlFor="cpf" required>CPF</FormLabel>
            <OutlinedInput
              id="cpf"
              name="cpf"
              type="phone"
              placeholder="CPF (Apenas números)"
              autoComplete="CPF cpf"
              required
              inputMode='numeric'
              onChange={(e) => handleCpfChange(e.target.value)}
              value={cpf}
            />
          </FormGrid>
        </Box>
      </FormGrid>


      <FormGrid item xs={12}>
        <FormLabel htmlFor="Endereço" required>Endereço</FormLabel>
        <OutlinedInput
          id="endereco"
          name="Endereço"
          type="text"
          placeholder="Endereço"
          autoComplete="address endereco address-line1"
          required
          inputMode='numeric'
          onChange={(e) => setAddress(e.target.value)}
          value={address}
        />
      </FormGrid>

      <FormGrid item xs={12}>
        <FormLabel htmlFor="Endereço" required>CEP</FormLabel>
        <OutlinedInput
          id="cep"
          name="cep"
          type="phone"
          placeholder="CEP"
          autoComplete="postal_code zip_code cep"
          required
          inputMode='numeric'
          onChange={(e) => setCep(e.target.value)}
          value={cep}
        />
      </FormGrid>

      <FormGrid item xs={12}>
        <Box sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: { xs: '24px', md: '16px' }
        }}>    
          <FormGrid item xs={12}>
            <FormLabel htmlFor="Cidade" required>Cidade</FormLabel>
            <OutlinedInput
              id="cidade"
              name="Cidade"
              type="text"
              placeholder="Cidade"
              autoComplete="shipping address-line2"
              required
              inputMode='numeric'
              onChange={(e) => setCity(e.target.value)}
              value={city}
            />
          </FormGrid>
          
          <FormGrid item xs={12}>
            <FormLabel htmlFor="Estado" required>Estado</FormLabel>
            <OutlinedInput
              id="estado"
              name="Estado"
              type="text"
              placeholder="Estado (Sigla)"
              autoComplete="shipping address-line2"
              required
              inputMode='numeric'
              onChange={(e) => handleStateChange(e.target.value)}
              value={state}
            />
          </FormGrid>
        </Box>
      </FormGrid>

    
    </Grid>
  );
}