import React from "react";

type Props = {
  dateString: string;
};

function dataPorExtenso(dataStr: string) {
  const meses: string[] = [
      "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
      "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
  ];

  // Separar a data e a hora da string
  const [dataPart] = dataStr.split('T');
  const [ano, mesNum, dia] = dataPart.split('-').map(Number);

  // Obter o nome do mês
  const mes = meses[mesNum - 1]; // Arrays em TypeScript começam do índice 0

  // Formatar a data por extenso
  return `${dia} de ${mes}, ${ano}`;
}

const DateFormatter = ({ dateString }: Props) => {
  dataPorExtenso(dateString);

  return <p>{dataPorExtenso(dateString)}</p>
};

export default DateFormatter;
