const fetcher = (...args) => fetch(...args).then(res => res.json()).then(a => a.data);

// const strapiURL = 'http://127.0.0.1:1337/';
// const blogToken = 'Bearer 8a4c68af2ba60db886e3d2a631b8d091849cb4b16f29ac92e1639b3797889e400e3c57cf13cafdcaa4c3ecb53ed3da957abf7f59734959f05926cbd39bf6cc3de9f05c87fa13d9a223758940cd072c1f65765bccf65dd630cd1bbc3c9f0679c215c2e7042d832e08bae085f8e505c440593663d8866aa99ce2956f09cab94ea3';
// const leadToken = 'Bearer 2317d13a1088eba2c4208be6651fefb04d5cb4ed6c3404a621e4e31dc45973dea275de7fb196f7945bed830a2fb248f4ed1e82e1c6e1ba22fff7c932b2b3e80a39a93cb5661874a6ddb247b1e74c5029feb39b0f7e73170f1b672c611ba86b283262d322793f4a4ae8daa2b4280dceb6c482806759de9d2318cd0481ed0d1df2'

// const checkoutUrl = 'http://localhost:3333';
// const checkoutToken = 'Bearer 3ad577b9c08ec5a56b8f437ad46a6fac308ef213cf4b4d66ad59160b679c20dc4302cd997be2a72f39dc56fcb0e871ac35a8fa1a8dad9072fb35fc81ad505dd08b41de03a38b8f7a79b0f9092ca77757e9d52311f031f88d5a4c531ee1186066a854ea6adab459b45b9b97ffd28195d35abae3e1af77d35d169f6d56b0afef3e';

const strapiURL = 'https://strapi-production-bb55.up.railway.app/';
const blogToken = 'Bearer c29beec1c8d0c9bed262ed515088b9c9c099cccce3f7543bf1903e8d63849377569f847836fada2139a05d592611dd9c7f83077df2d3f120981ec39a65e1aa549cb6ad41eaa09013ecbc023e2f838361b56a77585576d543a83338b51c07d135c238aeeb594ceef52cc4e8d3bd4b8c47cd25af747b5f191a0ec189d3306f4fd4';
const leadToken = 'Bearer dd3d76cb7b1f1c8f2ad92dfc56e211bcde873492f36ba268beddb7ea95128f75ea86e8b08677df724dceafaad36979dc47df293d982f2575db157ff0d214cfd09b7a2e872c52357f977f7144e67882fba035ab66e74141f69ed86ca15e6de4268de92e29b3285b04d77aefbd204e20cc2c703df072115855cc53910d8c1af128'
const checkoutUrl = 'https://med-academy-payment-production.up.railway.app';
const checkoutToken = 'Bearer 4c024d51c913de75f48a692f6b613f15f7908616d2d96fcf5f0e70d56a1a038ed0699350ee6050c4529db5e41a27f807080e49f4565057feef57bb4c0d8d59e3b7763cba02d1666ec3925635da368e8eeb789dfa86dc66b8d34deaec20d84a965f0a5791b6cd646c9c2751a94e3598d9a5c2a0adb205b7ce5806fa535208deec';


export default strapiURL;

export { checkoutUrl, fetcher, blogToken, checkoutToken, leadToken }
