import React from "react";
import CoverImage from "./cover-image.tsx";
import DateFormatter from "./date-formatter.tsx";

type Props = {
  title: string;
  coverImage: { url: string };
  date: string;
  excerpt: string;
  slug: string;
};

export function HeroPost({
  title,
  coverImage,
  date,
  excerpt,
  slug,
}: Props) {
  return (
    <section>
      <div className="mb-8 mt-32 md:mb-16">
        <CoverImage title={title} src={coverImage.url} slug={slug} />
      </div>
      <div className="md:grid md:grid-cols-2 md:gap-x-16 lg:gap-x-8 mb-20 md:mb-28">
        <div>
          <h3 className="mb-4 text-4xl lg:text-5xl leading-tight">
            <a href={`/posts/${slug}`} className="hover:underline">
              {title}
            </a>
          </h3>
          <div className="mb-4 md:mb-0 text-lg">
            <DateFormatter dateString={date} />
          </div>
        </div>
        <div>
          <p className="text-lg leading-relaxed mb-4">{excerpt}</p>
        </div>
      </div>
    </section>
  );
}
