import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const logoStyle = {
  width: '100%',
  height: 'auto',
};

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      {'Copyright © '}
      <Link href="/">Med Academy&nbsp;</Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: 'center', md: 'left' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: { xs: '16px', md: '40px' },
          }}
        >
          <Box sx={{ width: { xs: '140px', md: '344px' } }}>
            <img
              src={'/assets/logo-horizontal.png'}
              style={logoStyle}
              alt="logo of sitemark"
            />
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              sx={{
                marginBlock: 1.75,
                fontWeight: 700,
                lineHeight: '20px',
                color: (theme) => theme.palette.secondary.main,
              }}
              gutterBottom
            >
              Faça parte da nossa comunidade
            </Typography>
            <Typography variant="body2" color="text.secondary" mb={2}>
              Deixe o seu email para receber atualizações
            </Typography>
            <Stack direction="row" spacing={1} useFlexGap>
              <TextField
                id="outlined-basic"
                hiddenLabel
                size="small"
                variant="outlined"
                fullWidth
                aria-label="Enter your email address"
                placeholder="Digite seu e-mail"
                inputProps={{
                  autoComplete: 'off',
                  'aria-label': 'Enter your email address',
                }}
              />
              <Button
                variant="contained"
                color="secondary"
                sx={{ flexShrink: 0 }}
              >
                Inscrever-se
              </Button>
            </Stack>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>            
            <div className='flex flex-col items-center justify-center sm:mt-[15px] mt-[84px] '>
          <a href='#pricing' className='w-[381px] max-w-[90%] text-white bg-[#37067A] py-[6px] text-center font-bold text-[20px] rounded-md mt-5'>
            Matricule-se Agora!
          </a>
          </div>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: { xs: 4, sm: 8 },
          width: '100%',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <div>
          <Link color="text.secondary" href="#">
            Política de Privacidade
          </Link>
          <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link color="text.secondary" href="#">
            Termos de Serviços
          </Link>
          <Copyright />
        </div>
        {/* <Stack
          direction="row"
          justifyContent="left"
          spacing={1}
          useFlexGap
          sx={{
            color: 'text.secondary',
          }}
        >
          <IconButton
            color="inherit"
            href="https://github.com/mui"
            aria-label="GitHub"
            sx={{ alignSelf: 'center' }}
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://twitter.com/MaterialUI"
            aria-label="X"
            sx={{ alignSelf: 'center' }}
          >
            <TwitterIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.linkedin.com/company/mui/"
            aria-label="LinkedIn"
            sx={{ alignSelf: 'center' }}
          >
            <LinkedInIcon />
          </IconButton>
        </Stack> */}
      </Box>
    </Container>
  );
}