import * as React from 'react';

import List from '@mui/material/List';
import Typography from '@mui/material/Typography';


interface InfoProps {
  totalPrice: string;
  name: string;
  description: string;
}

export default function Info({ totalPrice, name, description }: InfoProps) {
  return (
    <React.Fragment>
      <Typography variant="subtitle2" color="text.secondary">
        {name}
      </Typography>
      <Typography variant="h4" gutterBottom>
        {totalPrice}
      </Typography>
      <List disablePadding>
        <Typography variant="body1" fontWeight="medium">
          {description}
        </Typography>
      </List>
    </React.Fragment>
  );
}