import strapiURL, { checkoutToken } from "./lib";

const fetchCourses = async () => {

  const options: RequestInit = {
    method: "GET",
    headers: {
      'Content-type': 'application/json',
      Authorization: checkoutToken
    },
    cache: 'no-store'
  };

  const response = await fetch(`${strapiURL}api/courses?populate=*`, options);

  if (!response.ok) {
    throw new Error(`Erro ao buscar posts: ${response.statusText}`);
  }

  const promise = await response.json();
  const data = await promise.data;

  return data;
}

const fetchCourseBySlug = async (courseSlug: string) => {

  const url = `${strapiURL}api/courses?filters[slug][$eq]=${courseSlug}`

  const options: RequestInit = {
    method: "GET",
    headers: {
      'Content-type': 'application/json',
      Authorization: checkoutToken
    },
    cache: 'no-store'
  };

  const response = await fetch(url, options);

  if (!response.ok) {
    throw new Error(`Erro ao buscar posts: ${response.statusText}`);
  }

  const promise = await response.json();
  const data = await promise.data[0];

  return data;
}

export { fetchCourses, fetchCourseBySlug };
