import React from "react";

export default function HeadsetIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="30" viewBox="0 0 29 30" fill="none">
      <path d="M25.3951 15.1762C25.3951 8.62082 20.3084 4.16699 14.6486 4.16699C9.04844 4.16699 3.90206 8.52529 3.90206 15.2478C3.18563 15.6538 2.70801 16.418 2.70801 17.3016V19.6897C2.70801 21.0032 3.78266 22.0778 5.09612 22.0778C5.75285 22.0778 6.29017 21.5405 6.29017 20.8838V15.1404C6.29017 10.5671 9.81264 6.56704 14.3859 6.4357C19.1143 6.29241 23.0069 10.0895 23.0069 14.7941V23.2719H14.6486C13.9918 23.2719 13.4545 23.8092 13.4545 24.4659C13.4545 25.1227 13.9918 25.66 14.6486 25.66H23.0069C24.3204 25.66 25.3951 24.5853 25.3951 23.2719V21.8151C26.0995 21.445 26.5891 20.7166 26.5891 19.8569V17.1105C26.5891 16.2747 26.0995 15.5463 25.3951 15.1762Z" fill="#36067A"/>
      <path d="M11.0671 17.3022C11.7266 17.3022 12.2612 16.7676 12.2612 16.1081C12.2612 15.4487 11.7266 14.9141 11.0671 14.9141C10.4076 14.9141 9.87305 15.4487 9.87305 16.1081C9.87305 16.7676 10.4076 17.3022 11.0671 17.3022Z" fill="#36067A"/>
      <path d="M18.2312 17.3017C18.8906 17.3017 19.4252 16.7671 19.4252 16.1076C19.4252 15.4482 18.8906 14.9136 18.2312 14.9136C17.5717 14.9136 17.0371 15.4482 17.0371 16.1076C17.0371 16.7671 17.5717 17.3017 18.2312 17.3017Z" fill="#36067A"/>
      <path d="M21.8134 13.7556C21.2403 10.3526 18.279 7.74951 14.7088 7.74951C11.0908 7.74951 7.19819 10.7466 7.50864 15.4512C10.458 14.2452 12.6789 11.6183 13.3117 8.41818C14.876 11.5585 18.088 13.7198 21.8134 13.7556Z" fill="#36067A"/>
    </svg>
  )
}