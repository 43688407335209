// eslint-disable-next-line @typescript-eslint/no-var-requires
// import 'dotenv/config'

import strapiURL from "../../../lib/lib.js";
import { Post } from "../interfaces/post.ts";
// import * as fs from 'fs';
// import matter from "gray-matter";
// import { join } from "path";

// const postsDirectory = join(process.cwd(), "_posts");

export async function fetchPosts(): Promise<Post[]> {

  const url = `${strapiURL}api/blog-posts?populate=*`

  const options: RequestInit = {
    method: "GET",
    // headers: {
    //   'Content-Type': 'application/json',
    //   Authorization: blogToken
    // },
    cache: 'no-store'
  }
  const response = await fetch(url, options);
  if (!response.ok) {
    throw new Error(`Erro ao buscar posts: ${response.statusText}`);
  }

  const promise = await response.json();
  const data = await promise.data;
  
  return data;
}


export async function getPostSlugs() {
  const posts = await fetchPosts();
  return posts.map(post => post.slug);
}

export async function getPostBySlug(slug: string) {
  const posts = await fetchPosts();
  const post = posts.find(post => post.slug === slug);
  if (!post) {
    throw new Error(`Post não encontrado para o slug: ${slug}`);
  }
  return post;
}

export async function getAllPosts(): Promise<Post[]> {
  const posts = await fetchPosts();
  
  return posts;
}

