
function VideoPlayer() {

  return (
    <div style={{ height: '100%', width: '100%'}}>
      <div style={{ height: '100%', width: '100%'}}>
        <iframe style={{ height: '100%', width: '100%'}} src="https://player.vimeo.com/video/938945101?h=a34f337d6c" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" title="Vídeo Institucional Med Academy">
        </iframe>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </div>
  );
}

export default VideoPlayer
