/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-case-declarations */
import * as React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';

import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { PaletteMode } from '@mui/material';
import { fetchCourseBySlug } from '../../../lib/apiCheckout.ts';

import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

import AddressForm from './AddressForm.tsx';
import getCheckoutTheme from './getCheckoutTheme.tsx';
import Info from './Info.tsx';
import InfoMobile from './InfoMobile.tsx';
import PaymentForm from './PaymentForm.tsx';
import Review from './Review.tsx';
import { useParams } from 'react-router-dom';
import formatMoney from '../../../utils/formatMoney.js'
import axiosReq from '../../../lib/axiosCheckout.ts';
// import ToggleColorMode from './ToggleColorMode.tsx';
import RefreshIcon from '@mui/icons-material/Refresh';


const steps = ['Dados do aluno', 'Dados de pagamento', 'Revisão'];

const logoStyle = {
  width: '130px',
  height: 'auto',
  marginLeft: '-4px',
  marginRight: '-8px',
};

interface CourseInterface {
  title: string,
  amount: number,
  description: string,
}

export default function CheckoutSucess() {
  const [currentCourse, setCurrentCourse] = React.useState<CourseInterface>({ title: 'Curso Indefinido', amount: 0, description: '' });
  const [paymentType, setPaymentType] = React.useState('creditCard');
  const [cardNumber, setCardNumber] = React.useState('');
  const [cvv, setCvv] = React.useState('');
  const [expirationDate, setExpirationDate] = React.useState('');
  const [cardName, setCardName] = React.useState('')
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [cpf, setCpf] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [cep, setCep] = React.useState('');
  const [state, setState] = React.useState('');
  const [city, setCity] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [pixQrCode, setPixQrCode] = React.useState(false);
  const [pixCode, setPixCode] = React.useState('');

  const [errorElement, setErrorElement] = React.useState(<div />);

  const [sucess, setSucess] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(3);

  const { courseSlug } = useParams();

  
  const showCustomTheme = true
  const mode  = 'light'
  
  const checkoutTheme = createTheme(getCheckoutTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  React.useEffect(() => {
    async function fetchStuff() {
      if (courseSlug !== undefined) {
        const data = await fetchCourseBySlug(courseSlug);
        setCurrentCourse(data);
      }
    }

    fetchStuff();
  }, [activeStep]);

  function createAlert(alertText: string) {
    const alertElement = <div 
      style={{ 
        position: 'absolute', top: '100px', left: 0, right: 0, textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', 
        backgroundColor: '#9865E0', padding: '12px', borderRadius: '8px', fontSize: '16px', color: '#FFF', width: '400px',
        maxWidth: '95%', zIndex: 10,
      }}
    >
      {alertText}
    </div>;
    setErrorElement(alertElement);
    setTimeout(() => {
      setErrorElement(<div />);
    }, 1500)
  }

  
  if (currentCourse === undefined || currentCourse.title === 'Curso Indefinido') {
    return <div />
  }

  const { description, amount, title } = currentCourse;  

  async function checkPixSucess(interval, res) {
    const pixReq = await axiosReq.get(`/api/order/pix?id=${res.data.id}`);
    console.log(pixReq.data.status);
    console.log(pixReq.data.status === 'paid')
    if (pixReq.data.status === 'paid') { 
      setActiveStep(2);
      setTimeout(() => {
        clearInterval(interval);
      }, 2500)
    }
  }

  const handleNext = async () => {
    const body: any = {
      customer: { 
        name, 
        email, 
        document: cpf.replace('.', '').replace('.', '').replace('-', ''), 
        phones: {
          mobile_phone: {
            country_code: '55',
            area_code: phone.substring(0, 2),
            number: phone.substring(2, phone.length - 1),
          }
        },
        document_type: 'CPF', 
        type: 'individual' 
      },
      antifraud_enabled: true,
      payments: [ 
        { 
          payment_method: 'credit_card',
          credit_card: { 
            recurrence: false,
            installments: 1,
            statement_descriptor: "MEDACADEMY",
            card: { 
              number: cardNumber.replace(/\s+/g, ''), holder_name: cardName, exp_month: Number(expirationDate.split('/')[0]), exp_year: Number(expirationDate.split('/')[1]), cvv, 
              billing_address: { line_1: address, zip_code: cep, city: city, state: state, country: 'BR' }
          }},
      } ],
      items: [ { code: courseSlug, quantity: 1, amount, description } ]
    }
    switch (activeStep) {
      case 0:
        if (name !== '' && cpf !== '' && cpf.length === 14 && phone !== '' && address !== '' && cep !== '' && state !== '' && city !== '') {
          if (email !== '' && /^\S+@\S+\.\S+$/.test(email)) {
            setActiveStep(activeStep + 1)
          } else {
            createAlert('Preencha o campo Email com um email váido');
          }
        } else {
          createAlert('Preenha todos os campos com dados válidos')
        }
        break;

      case 1:
        if (paymentType === 'creditCard') {
          if (cardName !== '' && cvv !== '' && cardNumber !== '' && expirationDate !== '') {
            setActiveStep(activeStep + 1)
          } else {
            createAlert('Preencha todos os campos relacionados ao cartão')
          }
        } else {
          try {
            body.payments = [{
              payment_method: "pix",
              Pix: {
                expires_in: 300
              },
              amount: amount - (amount * 0.05)
            }]

            setIsLoading(true);
            const res = await axiosReq.post('/api/order/pix', body);
            console.log(res.data);
            setPixQrCode(res.data.charges[0].last_transaction.qr_code_url);
            setPixCode(res.data.charges[0].last_transaction.qr_code);

            const interval = setInterval(async () => {
              checkPixSucess(interval, res)

            }, 10000);

            console.log(interval)

            setSucess(true);
            setActiveStep(activeStep + 1);
            setIsLoading(false);

          } catch (error) {
            setIsLoading(false);
            setActiveStep(activeStep + 1);
          }
        }
        break;

      case 2:

        if (paymentType === 'creditCard') {
          try {
            setIsLoading(true);
            await axiosReq.post('/api/order/card', {
              data: body
            });
            setSucess(true);
            setActiveStep(activeStep + 1);
            setIsLoading(false);
          } catch (error) {
            setActiveStep(activeStep + 1);
          }
        } else {
          setIsLoading(false);
          setActiveStep(activeStep + 1);
        }


        break;
    
      default:
        setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  function getStepContent(step: number) {
    const amountForReview = paymentType === 'creditCard' ? formatMoney(amount) : formatMoney(amount - (amount * 0.05))
    switch (step) {
      case 0:
        return <AddressForm name={name} setName={setName} email={email} setEmail={setEmail} cpf={cpf} setCpf={setCpf} address={address} setAddress={setAddress} cep={cep} setCep={setCep} state={state} setState={setState} city={city} setCity={setCity} phone={phone} setPhone={setPhone} />;
      case 1:
        return <PaymentForm paymentType={paymentType} setPaymentType={setPaymentType} setCvv={setCvv} cardNumber={cardNumber} setCardNumber={setCardNumber} expirationDate={expirationDate} setExpirationDate={setExpirationDate} cvv={cvv} cardName={cardName} setCardName={setCardName} setCardInstallments={setCardInstallments} />;
      case 2:
        return <Review paymentType={paymentType} pixQrCode={pixQrCode} pixCode={pixCode}
          name={name} cardName={cardName} total={amountForReview} title={title} description={description} cardNumber={cardNumber} expirationDate={expirationDate} email={email}
        />;
      case 3:
        return <div>
          Teste
        </div>
      default:
        throw new Error('Unknown step');
    }
  }

  return (
    <ThemeProvider theme={showCustomTheme ? checkoutTheme : defaultTheme}>
      <CssBaseline />
      <Grid id="baseComponent" container sx={{ height: { xs: '100%', sm: '100dvh' }, position: 'relative' }}>
        {errorElement}
        <Grid
          item
          xs={12}
          sm={5}
          lg={4}
          sx={{
            display: { xs: 'none', md: 'flex' },
            flexDirection: 'column',
            backgroundColor: 'background.paper',
            borderRight: { sm: 'none', md: '1px solid' },
            borderColor: { sm: 'none', md: 'divider' },
            alignItems: 'start',
            pt: 4,
            px: 10,
            gap: 4,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'end',
              height: 150,
            }}
          >
            <Button
              startIcon={<ArrowBackRoundedIcon />}
              component="a"
              href="/"
              sx={{ ml: '-8px', display: 'flex', alignItems: 'center', gap: 1 }}
            >
              <img
                src={
                  '/assets/logo-horizontal.png'
                }
                style={logoStyle}
                alt="Sitemark's logo"
              />
            </Button>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              width: '100%',
              maxWidth: 500,
            }}
          >
            <Info name={title} totalPrice={formatMoney(amount)} description={description} />
          </Box>
        </Grid>
        <Grid
          item
          sm={12}
          md={7}
          lg={8}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '100%',
            width: '100%',
            backgroundColor: { xs: 'transparent', sm: 'background.default' },
            alignItems: 'start',
            pt: { xs: 2, sm: 4 },
            px: { xs: 2, sm: 10 },
            gap: { xs: 4, md: 8 },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: { sm: 'space-between', md: 'flex-end' },
              alignItems: 'center',
              width: '100%',
              maxWidth: { sm: '100%', md: 600 },
            }}
          >
            <Box
              sx={{
                display: { xs: 'flex', md: 'none' },
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <Button
                startIcon={<ArrowBackRoundedIcon />}
                component="a"
                href="/"
                sx={{ alignSelf: 'start', gap: 1 }}
              >
                <img
                  src={
                    '/assets/logo-horizontal.png    '
                  }
                  style={logoStyle}
                  alt="Sitemark's logo"
                />
              </Button>
              {/* <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} /> */}
            </Box>
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                flexGrow: 1,
                height: 150,
              }}
            >
              {/* <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} /> */}
              <Stepper
                id="desktop-stepper"
                activeStep={activeStep}
                sx={{
                  width: '100%',
                  height: 40,
                }}
              >
                {steps.map((label) => (
                  <Step
                    sx={{
                      ':first-child': { pl: 0 },
                      ':last-child': { pr: 0 },
                    }}
                    key={label}
                  >
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Box>
          <Card
            sx={{
              display: { xs: 'flex', md: 'none' },
              width: '100%',
            }}
          >
            <CardContent
              sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
                ':last-child': { pb: 2 },
              }}
            >
              <div>
                <Typography variant="subtitle2" gutterBottom>
                  {title}
                </Typography>
                <Typography variant="body1">
                  {formatMoney(amount)}
                </Typography>
              </div>
              <InfoMobile name={title} description={description} totalPrice={formatMoney(amount)} />
            </CardContent>
          </Card>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              width: '100%',
              maxWidth: { sm: '100%', md: 600 },
              maxHeight: '720px',
              gap: { xs: 5, md: 'none' },
            }}
          >
            <Stepper
              id="mobile-stepper"
              activeStep={activeStep}
              alternativeLabel
              sx={{ display: { sm: 'flex', md: 'none' } }}
            >
              {steps.map((label) => (
                <Step
                  sx={{
                    ':first-child': { pl: 0 },
                    ':last-child': { pr: 0 },
                    '& .MuiStepConnector-root': { top: { xs: 6, sm: 12 } },
                  }}
                  key={label}
                >
                  <StepLabel
                    sx={{ '.MuiStepLabel-labelContainer': { maxWidth: '70px' } }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length ? (
             
              <Stack spacing={2} useFlexGap>
                <Typography variant="h1">
                  { sucess ? '📚' : '🚨' }
                </Typography>
                <Typography variant="h5">
                  { sucess ? 'Obrigado pelo seu pedido!' : 'Falha na compra!' }
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  { sucess ? 'Nós enviaremos a confirmação de sua compra pelo email.' : 'Por favor, tente novamente.' }
                </Typography>
                <a style={{ padding: '16px', color: 'white', backgroundColor: '#36067A', borderRadius: '6px' }} href={sucess ? '/' : `/checkout/${courseSlug}`}>
                  { sucess ? 'Voltar a página inicial' : 'Tentar novamente' }
                </a>
              </Stack>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column-reverse', sm: 'row' },
                    justifyContent: activeStep !== 0 ? 'space-between' : 'flex-end',
                    alignItems: 'end',
                    flexGrow: 1,
                    gap: 1,
                    pb: { xs: 12, sm: 0 },
                    mt: { xs: 2, sm: 0 },
                    mb: '60px',
                  }}
                >
                  {activeStep !== 0 && (
                    <Button
                      startIcon={<ChevronLeftRoundedIcon />}
                      onClick={handleBack}
                      variant="text"
                      style={{ backgroundImage: 'none', color: '#9865E0' }}
                      sx={{
                        display: { xs: 'none', sm: 'flex' },
                      }}
                    >
                      Voltar
                    </Button>
                  )}
                  {activeStep !== 0 && (
                    <Button
                      startIcon={<ChevronLeftRoundedIcon />}
                      onClick={handleBack}
                      variant="outlined"
                      fullWidth
                      style={{ backgroundImage: 'none', color: '#9865E0' }}
                      sx={{
                        display: { xs: 'flex', sm: 'none' },
                      }}
                    >
                      Voltar
                    </Button>
                  )}
                  
                  <Button
                    variant="contained"
                    endIcon={<ChevronRightRoundedIcon />}
                    onClick={isLoading === false ? handleNext : () => createAlert('Espere o loading acabar para prosseguir!')}
                    style={{ backgroundImage: 'none', backgroundColor: '#9865E0' }}
                    sx={{
                      opacity: 1,
                      width: { xs: '100%', sm: 'fit-content' },
                    }}
                  >
                    {isLoading ? (<div className='animate-spin'><RefreshIcon /></div>) : activeStep === steps.length - 1 ? 'Finalizar Compra' : 'Próximo' }
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}