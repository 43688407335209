import React from "react";
import "./markdown-styles.module.css";
import Markdown from 'markdown-to-jsx';

type Props = {
  content: string;
};

export function PostBody({ content }: Props) {
  return (
    <div className="max-w-[1440px] mx-auto markdown">
      <Markdown>
        {content}
      </Markdown>
    </div>
  );
}
