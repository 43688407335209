// react
import * as React from "react"

// router
import { createBrowserRouter, RouterProvider } from "react-router-dom"

// Pages
import { 
  LandingPage, 
  CheckoutPage,
} from "../components/index.ts"
import Index from "../components/blog/page.tsx"
import Post from '../components/blog/posts/page.tsx';
import CheckoutSucess from "../components/Templates/CheckoutPage/CheckoutSucess.tsx";


const router = createBrowserRouter([
  {
    path: '/',
    element: <LandingPage />
  },
  {
    path: '/checkout/:courseSlug',
    element: <CheckoutPage />
  },
  {
    path: '/checkout/:courseSlug/sucess',
    element: <CheckoutSucess />
  },
  {
    path: '/blog',
    element: <Index />
  },
  {
    path: '/posts/:slug',
    element: <Post />
  }
])

export default function Routes() {
  return <RouterProvider router={router} />
}