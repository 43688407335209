import * as React from "react";

import Container from "./_components/container.tsx";
import { HeroPost } from "./_components/hero-post.tsx";
import { MoreStories } from "./_components/more-stories.tsx";
import {  fetchPosts } from "./lib/api.ts";
import type { Post } from "./interfaces/post.ts";
import AppAppBar from "../Templates/LandingPage/components/AppAppBar.tsx";

import { ThemeProvider, createTheme } from '@mui/material/styles';
import getLPTheme from '../Templates/LandingPage/getLPTheme.tsx';

export default function Index() {
  const [posts, setAllPosts] = React.useState<Post[]>([]);

  const showCustomTheme = true
  const mode = 'light'

  const LPtheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  React.useEffect(() => {
    async function fetchPostss() {
      const allPosts = await fetchPosts();
      setAllPosts(allPosts)
    }

    fetchPostss();
  }, []);
  
  if (posts.length === 0) {
    return <div className="flex flex-col h-full items-center justify-center">
      <h1 className="text-center text-[48px] text-[#36067A]">
        Carregando...
      </h1>
      </div>
  }

  return (
    <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
      <Container>
        <AppAppBar />
        <HeroPost
          title={posts[0].title}
          coverImage={posts[0].coverImage}
          date={posts[0].createdAt}
          slug={posts[0].slug}
          excerpt={posts[0].excerpt}
        />
        {posts.length > 0 && <MoreStories posts={posts} />}
      </Container>
    </ThemeProvider>
  );
}
