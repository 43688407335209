import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import GearIcon from '../../../Icons/GearIcon.tsx';
import HammerIcon from '../../../Icons/HammerIcon.tsx';
import MagicWandIcon from '../../../Icons/MagicWandIcon.tsx';
import HeadsetIcon from '../../../Icons/HeadsetIcon.tsx';

const items = [
  {
    icon: <GearIcon />,
    title: 'Prova', 
    description: 'Domine a prova de residência com a metodologia MedAcademy.'
  },
  {
    icon: <HammerIcon />,
    title: 'Jornada',
    description: 'Esteja preparado para o próximo nível da sua jornada profissional'
  },
  {
    icon: <MagicWandIcon />,
    title: 'Comunidade',
    description: 'Faça parte da comunidade que mais aprova na PROVA de residência do CERMAM.',
  },
  {
    icon: <HeadsetIcon />,
    title: 'Aulas',
    description: 'Preparatório completo: aulas presencial e apostilas de ótima qualidade',
  }
];

export default function Highlights() {
  return (
    <section id="highlights">
      <Box
        id="highlights"
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
          color: 'white',
          bgcolor: '#36067A',
        }}
      >
        <Container
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: { xs: 3, sm: 6 },
          }}
        >
          <Box
            sx={{
              width: { sm: '100%', md: '60%' },
              textAlign: { sm: 'left', md: 'center' },
            }}
          >
            <Typography component="h2" sx={{ fontSize: { xs: 32, md: 40 } }}>
              Nosso objetivo é sua 
              <Typography sx={{ fontSize: { xs: 42, md: 68 } }} fontWeight={700}>aprovação.</Typography>
            </Typography>
          </Box>
          <Grid container spacing={2.5}>
            {items.map((item, index) => (
              <Grid item xs={12} sm={6} md={6} key={index}>
                <Stack
                  direction="column"
                  color="inherit"
                  component={Card}
                  spacing={1}
                  useFlexGap
                  sx={{
                    p: 3,
                    height: '100%',
                    border: '1px solid',
                    borderColor: 'grey.800',
                    background: 'transparent',
                    backgroundColor: 'grey.900',
                  }}
                >
                  <Box>{item.icon}</Box>
                  <div>
                    <Typography sx={{ color: 'text.primary', fontWeight: 700, fontSize: '18px' }} fontWeight="medium" gutterBottom>
                      {item.title}
                    </Typography>
                    <Typography sx={{ color: 'text.primary', fontWeight: 500, fontSize: '14px', lineHeight: '150%' }}>
                      {item.description}
                    </Typography>
                  </div>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </section>
  );
}
